import './_AboutCompany.scss'
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import max from '../../../assets/images/max.png'
import lawrence from '../../../assets/images/lawrence.png'
import {useEffect, useState} from "react";
import MmailchimpSubscribe from "../../popup/MmailchimpSubscribe";
import Popup from "../../popup/Popup";


const AboutCompany = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const openPopup = () => {
        setIsPopupOpen(true);
        document.body.classList.add('disable-scroll');
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        document.body.classList.remove('disable-scroll');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (<>
            <Header openPopup={(v) => openPopup(v)}/>
            <div className='about-company-box'>
                <div className='container'>
                    <div className='crumbs'>
                        <p><a href="/">Home</a> / <span>About Company</span></p>
                    </div>
                </div>
                <div className='container'>
                    <div className='left-box'>
                        <h2>About Company </h2>


                        <h4> The idea to start PivotHawk was born in the small village of Gem, in the heart of Alberta's warm prairies. Our founders, Lawrence and Maxim, understand the challenges faced by Canadian farmers intimately, as they are hardworking prairie farmers themselves. They know exactly how much time, effort, and energy farming demands.
                        </h4>
                        <h4> Having developed a solution to their own problem, they created PivotHawk to share this innovation with the world and ease the burdens of not only Canadian farmers but also farmers everywhere who need it.
                        PivotHawk: Created by farmers, for farmers!     </h4>


                          <div className='box-info'>
                            {/*<p>  For partnership inquiries or any other proposals, please don't hesitate to contact us: <a href="mailto:mg@pivothawk.com"> mg@pivothawk.com</a> </p>*/}
                            {/*<p> PO Box: Bassano, AB T0J0B0, Canada</p>*/}
                            <p>If you have any suggestions for partnerships, you can contact us via the following methods or social media    </p>
                        </div>
                    </div>
                    <div className='right-box'>
                        <h2>About Company </h2>
                     <div className='box'>
                        <div className='one'>
                            <div className='arrow'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
                                    <path d="M27.5 26.2578C20.8333 21.9245 6.7 10.9578 3.5 1.75781" stroke="#010035" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1 10.2578C1.33333 8.09115 2.3 3.25781 3.5 1.25781C5.5 2.85781 9.66667 3.92448 11.5 4.25781" stroke="#010035" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                Lawrence Scheibner
                                co founder
                            </div>
                            <img src={lawrence} alt=""/>
                        </div>
                        <div className='two'>
                            <div className='arrow'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
                                    <path d="M27.3431 1.24258C20.7189 5.64062 6.69303 16.7444 3.58276 25.9751" stroke="#010035" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M1.00001 17.4995C1.35443 19.6628 2.36811 24.4865 3.58753 26.4747C5.57185 24.8553 9.72794 23.7481 11.5579 23.3969" stroke="#010035" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                Maxim Goncharov
                                co founder
                            </div>
                            <img src={max} alt=""/>
                        </div>
                     </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <Popup isOpen={isPopupOpen} onClose={closePopup}>
                <MmailchimpSubscribe closeForm={closePopup}/>
            </Popup>
        </>
    );
};

export default AboutCompany
