import './_Popup.scss'

const Popup = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
        <div className="popup-overlay">
            <div className="popup">
                <div className="close-button" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M2 17.75L0.25 16L7.25 9L0.25 2L2 0.25L9 7.25L16 0.25L17.75 2L10.75 9L17.75 16L16 17.75L9 10.75L2 17.75Z" fill="black"/>
                    </svg>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Popup
