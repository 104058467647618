import CloseIcon from "../../assets/images/icons/CloseIcon.svg";
import React from "react";
import "./Snackbar.css"

const CloseButton = ({ closeToast }) => (
  <div onClick={closeToast}>
    <img className='closeIcon' src={CloseIcon} alt="Close"/>
  </div>
);

export default CloseButton