import {axiosReq} from "../utils/request";


export const devices = async () => {
  const res = await axiosReq.get('/devices')
  return res.data
}
export const adDevices = async ({newDevice}) => {
  const res = await axiosReq.post('/device', {...newDevice})
  return res.data
}
export const editDevices = async ({editDevice}) => {
  const res = await axiosReq.patch('/device', {...editDevice})
  return res.data
}

export const deleteDevice = async (removeData) => {
  const res = await axiosReq.delete(`/device/${removeData.id}`,'')
  return res.data
}
