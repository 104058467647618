import './_LoginPage.scss'
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import {useLocalStorage} from "../../../utils/useLocalStorage";

import {useEffect, useState} from "react";

import {useNavigate} from 'react-router-dom';
import {Button, TextField} from "@mui/material";
import {useQuery} from "react-query";
import {signIn} from "../../../services/auth";
import {showSnackBar} from "../../Snackbar/Snackbar";

const LoginPage = () => {

    const navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
    const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", "");

    const [userID, setUserID] = useLocalStorage("userID", "");
    const [userEMail, setUserEmail] = useLocalStorage("userEmail", "");
    const [userRole, setRole] = useLocalStorage("role", "");

    const [credentials, setCredentials] = useState({email: '', password: ''})


    const {refetch: fetchSignIn, error,} = useQuery(['sign-in'], () => signIn({credentials}), {
        enabled: false,
        retry: false,
        onSuccess: (data) => {
            setAccessToken(data?.accessToken)
            setRefreshToken(data?.refreshToken)
            setUserID(data?._id)
            setUserEmail(data?.email)
            setRole(data?.role)
            navigate('/dashboard');
            showSnackBar("success", `Login: ${userEMail} successful!`)

        },
        onError: (err) => {
            console.log(err?.response)
            showSnackBar("error", `Login ${err?.response?.status === 401 ? 'Invalid credentials!' : error?.message}`)
        }
    })
    const handleSignIn = async () => {
        if (!credentials.email || !credentials.password) {
            showSnackBar("error", "Email and password are required!")
            return;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(credentials.email)) {
            showSnackBar("error", "Please enter a valid e-mail address")
            return;
        }

        try {
           const req= await fetchSignIn();
        } catch (error) {
            return showSnackBar("error", "There was an error logging in. Please try again")
        }
    };

    useEffect(() => {
      if(accessToken?.length > 0  && refreshToken?.length > 0 ){
          navigate('/dashboard');
      }
    }, []);

    return (<>
            <Header/>
            <div className='login-wrapper'>
                <div className='container'>
                    <div className='box-login'>
                        <TextField
                            sx={{width: '100%'}}
                            id="standard-helperText"
                            label="Login"
                            required
                            helperText="Enter your login"
                            variant="standard"
                            defaultValue={credentials.email}
                            onChange={(e) => setCredentials({...credentials, email: e.target.value})}
                        />
                        <TextField
                            sx={{width: '100%'}}
                            id="standard-helperText"
                            label="Password"
                            type='password'
                            required
                            helperText="Enter your password"
                            variant="standard"
                            defaultValue={credentials.password}
                            onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                        />
                        <div style={{display: 'flex', justifyContent: "end", width: '100%'}}>
                            <Button onClick={() => handleSignIn()} variant="text">Login</Button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default LoginPage
