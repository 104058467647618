import './_stepSection.scss'
import bgSteps from "../../../../assets/images/bgSteps.png";



const StepSection = () => {
    return ( <div className='steps'>
            <div className='container'>
                <div className='bg' style={{backgroundImage: `url(${bgSteps})`}}>
                    <div className='box'>
                        <div className='left-box'>
                            <h2>Take <br/>3 easy steps to PivotHawk</h2>
                        </div>
                        <div className='right-box'>
                            <div>
                                <p>1. Step</p>
                                <h4>Order device</h4>
                            </div>
                            <div>
                                <p>2. Step</p>
                                <h4>Replace your light bulb with PivotHawk</h4>
                            </div>
                            <div>
                                <p>3. Step</p>
                                <h4>Sign up to get started!</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepSection
