import './_buy.scss'
import bgSteps from "../../../../assets/images/bgSteps.png";
import {useNavigate} from "react-router-dom";


const BuySection = ({openPopup}) => {
    const navigate = useNavigate();

    return ( <div className='buy'>
            <div className='box'>
                <h2>Buy your Pivot</h2>
                <div>
                    <h2> Hawk <span> now!</span></h2>
                    <button onClick={()=>navigate('/buy')}>
                        Order page
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BuySection
