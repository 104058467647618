import './_homeHeroSection.scss'
import bgImage from '../../../../assets/images/hero-bg.jpg'
import android from '../../../../assets/images/android-btn.svg'
import ios from '../../../../assets/images/ios-btn.svg'
import {useNavigate} from "react-router-dom";


const HomeHeroSection = ({openPopup}) => {
    const navigate = useNavigate();

    return (<div className='hero-section'>
            <div className='box' style={{backgroundImage: `url(${bgImage})`}}>
                <div className='container'>
                    <div className='box-content'>
                        <h1>Stop worrying about your pivot!</h1>
                        <div className='box-btn'>
                            <div><a className='btn-white' onClick={()=>  navigate('/buy')}>Buy now</a></div>
                            {/*<div className='box-child'>*/}
                            {/*    <div><a href="/#" onClick={()=>openPopup(true)}><img src={android} alt="download android"/></a></div>*/}
                            {/*    <div><a href="/#" onClick={()=>openPopup(true)}><img src={ios} alt="download ios"/></a></div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomeHeroSection
