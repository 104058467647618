import './_Faq.scss'
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import {useEffect, useState} from "react";

const faqData = [
    {
        question: 'How does PivotHawk work?',
        answer: 'PivotHawk monitors your irrigation pivots by using advanced sensors and cellular connectivity. It tracks the status of the pivots in real-time and sends alerts to your dashboard on our web platform if any issues or unexpected shutdowns occur, ensuring your irrigation system operates smoothly and efficiently.'
    },
    {
        question: 'What tasks does PivotHawk solve?',
        answer: 'PivotHawk addresses the critical issue of irrigation system downtime by providing timely alerts for any unexpected shutdowns or malfunctions. This ensures that farmers can respond quickly, maintaining the efficiency and productivity of their irrigation systems and ultimately protecting their crops.'
    },
    {
        question: 'What is the current cost of the device?',
        answer: 'The current price of PivotHawk is displayed on the order page, but it may vary depending on specific requirements and quantities. For detailed pricing information, please contact our sales team directly and they will provide you with a customized quote.'
    },
    {
        question: 'What is the warranty period?',
        answer: 'PivotHawk comes with a one-year warranty that covers any manufacturing defects or device malfunctions. This ensures peace of mind for our customers, knowing that their investment is protected.'
    },
    {
        question: 'How do I know when PivotHawk stops working?',
        answer: 'If PivotHawk stops working or loses connection, it will immediately notify you through our web platform. This feature ensures that you are always aware of the device’s operational status and can take prompt action if necessary.'
    },
    {
        question: 'Who can operate the PivotHawk platform?',
        answer: 'The PivotHawk platform can be operated by anyone who has access to the personal account and the necessary authorization. This makes it easy for multiple users, such as farm managers and workers, to monitor the irrigation system using our web platform.'
    },
    {
        question: 'How will PivotHawk notify me if the irrigation system is malfunctioning?',
        answer: 'PivotHawk sends instant alerts to your personal account on our web platform, which you can access via mobile phone or desktop, if it detects any faults or problems in your irrigation system. These real-time alerts allow you to resolve issues quickly and efficiently.'
    },
    {
        question: 'Do I need to order the installation of PivotHawk from a professional installer?',
        answer: 'No, PivotHawk is designed for easy self-installation. The user-friendly setup process eliminates the need for a professional installer, allowing you to get your system up and running quickly and with minimal hassle.'
    },
    {
        question: 'What are the fees for using PivotHawk?',
        answer: 'The fees for using PivotHawk include an annual service charge. This fee covers continuous support, updates, and access to all features. For detailed pricing, please contact us directly or visit our pricing page.'
    },
    {
        question: 'How does PivotHawk ensure data security and confidentiality of farm information?',
        answer: 'PivotHawk uses advanced encryption and security techniques to protect data, ensuring that information is kept confidential and inaccessible to outsiders.'
    },
    {
        question: 'Can PivotHawk be used to monitor other aspects of the farm besides irrigation systems?',
        answer: 'PivotHawk is not currently designed to monitor other aspects of the farm, but we are actively considering various technical solutions to expand the functionality in the future.'
    },
    {
        question: 'Are you a Canadian company?',
        answer: 'Yes, our main representative is located in Gem, Alberta. For more information, please visit our dealers location page.'
    }
];
const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (<>
        <Header/>
            <div className='faq-box'>
                <div className='container'>
                    <div className='title-box'>
                        <h3>Frequently asked questions</h3>
                        <p>Our Frequently Asked Questions (FAQ) section provides comprehensive answers to common queries about PivotHawk</p>
                    </div>
                       <div className="faq-container">
                           {faqData.map((item, index) => (
                               <div className={`faq-item ${index === activeIndex ? 'active' : ''}`} key={index}>
                                   <div onClick={() => toggleAccordion(index)} className='btn-func'>
                                       {index === activeIndex ?   <svg xmlns="http://www.w3.org/2000/svg" width="43" height="44" viewBox="0 0 43 44" fill="none">
                                           <path d="M22.4507 23.2374L19.9758 23.2374L10.0763 23.2374V20.7626L19.9758 20.7626L22.5 20.7626H22.4507H32.3502V23.2374H22.4507Z" fill="black"/>
                                       </svg> :
                                           <svg xmlns="http://www.w3.org/2000/svg" width="43" height="44" viewBox="0 0 43 44" fill="none">
                                               <g clipPath="url(#clip0_35_689)">
                                                   <path d="M22.4507 33.1369L19.9758 33.1369L19.9758 23.2374L10.0763 23.2374V20.7626L19.9758 20.7626L19.9758 10.8631L22.4507 10.8631L22.4507 20.7626H32.3502V23.2374H22.4507L22.4507 33.1369Z" fill="black"/>
                                               </g>
                                               <defs>
                                                   <clipPath id="clip0_35_689">
                                                       <rect width="30" height="30" fill="white" transform="translate(0 22) rotate(-45)"/>
                                                   </clipPath>
                                               </defs>
                                           </svg>}
                                   </div>
                                   <div className="faq-question"><h4>{item.question}</h4></div>
                                  <div className='faq-answer'>
                                      {index === activeIndex && item.answer.split('\n').map((line, i) => (
                                          <p key={i}>{line}</p>
                                      ))}
                                  </div>
                               </div>
                           ))}
                       </div>

                </div>
            </div>
        <Footer/>
        </>
    );
};

export default Faq
