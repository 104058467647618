import './_infoProduct.scss'
// import desktop from "../../../../assets/images/desktop.png";
import desktop from "../../../../assets/images/new-desktop.png";


const InfoProduct = () => {
    return (<div className='info-product'>
            <div className='container custom-container'>
                <div className="box-img">
                    <div>
                        <img src={desktop}/>
                    </div>
                </div>
                <div className='box-text'>
                    <div className='one-item'>
                        <h3>Desktop and Mobile Versions: User-Friendly and Easy to Use</h3>
                        <p>
                            Our platform offers user-friendly desktop and mobile versions, providing convenient access
                            to pivot monitoring from any location, whether it be the office or elsewhere around the
                            globe </p>
                    </div>
                    <div className='two-item'>
                        <h3>Our clients:</h3>
                        <ul>
                            <li>1. Farmers and agrarians</li>
                            <li>2. Owners and managers of agricultural enterprises</li>
                            <li>3. Manufacturers of irrigation equipment</li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InfoProduct
