import './_PrivacyPolicy.scss'
import Header from "../../header/Header";
import Footer from "../../footer/Footer";

const PrivacyPolicy = () => {
    return (<>
            <Header/>
            <div className="privacy-policy">
                <div className='container'>

                    <h1>Privacy Policy</h1>
                    <p>At Pivot Hawk, we are committed to protecting your privacy. This policy outlines how we collect,
                        use,
                        and protect your personal information when you use our services.</p>

                    <h2>Information Collection:</h2>
                    <ul>
                        <li>
                            <strong>Personal Information:</strong> We collect personal information that you provide to
                            us,
                            such as your name, email address, phone number, and payment information.
                        </li>
                        <li>
                            <strong>Usage Data:</strong> We automatically collect information about your interactions
                            with
                            our website and services, including IP address, browser type, and pages visited.
                        </li>
                    </ul>

                    <h2>Use of Information:</h2>
                    <ul>
                        <li>
                            <strong>Service Provision:</strong> We use your personal information to provide, maintain,
                            and
                            improve our services.
                        </li>
                        <li>
                            <strong>Communication:</strong> We may use your contact information to send you updates,
                            newsletters, and marketing materials.
                        </li>
                        <li>
                            <strong>Analytics:</strong> We analyze usage data to enhance user experience and improve our
                            services.
                        </li>
                    </ul>

                    <h2>Data Security:</h2>
                    <p>We implement industry-standard security measures to protect your personal information from
                        unauthorized access, alteration, or disclosure.</p>
                    <p><strong>Third-Party Disclosure:</strong> We do not sell, trade, or otherwise transfer your
                        personal
                        information to outside parties except as described in this policy or with your consent.</p>

                    <h2>Terms of Service</h2>
                    <p>By using Pivot Hawk's services, you agree to comply with and be bound by the following terms and
                        conditions.</p>

                    <h2>Use of Services:</h2>
                    <ul>
                        <li>
                            <strong>Eligibility:</strong> You must be at least 18 years old to use our services.
                        </li>
                        <li>
                            <strong>Account Responsibility:</strong> You are responsible for maintaining the
                            confidentiality
                            of your account information and for all activities that occur under your account.
                        </li>
                    </ul>

                    <h2>Service Access:</h2>
                    <p>We strive to ensure the continuous availability of our services but do not guarantee
                        uninterrupted
                        access. We reserve the right to suspend or terminate services without prior notice.</p>

                    <h2>Intellectual Property:</h2>
                    <p>All content, trademarks, and data on our website and within our services are owned by Pivot Hawk
                        or
                        our licensors and are protected by intellectual property laws. You may not reproduce,
                        distribute, or
                        create derivative works without our explicit consent.</p>

                    <h2>User Conduct:</h2>
                    <p>You agree not to use our services for any unlawful purpose or in a manner that could damage,
                        disable,
                        or impair our services.</p>

                    <h2>Refund Policy</h2>
                    <p>Our refund policy outlines the terms under which you may request a refund for purchases made
                        through
                        PivotHawk</p>

                    <h2>Eligibility:</h2>
                    <ul>
                        <li>The product must be returned in its original condition and packaging.</li>
                        <li>Proof of purchase must be provided.</li>
                    </ul>

                    <h2>Refund Process:</h2>
                    <p>To request a refund, please contact our customer support team at support@pivothawk.com. Once your
                        return is received and inspected, we will notify you of the approval or rejection of your
                        refund.</p>

                    <h2>Refunds:</h2>
                    <p>Approved refunds will be processed within 7-10 business days and will be credited to your
                        original
                        method of payment.</p>

                    <h2>Warranty Policy</h2>
                    <p>Our products come with a one-year limited warranty from the date of purchase. This warranty
                        covers
                        defects in materials and workmanship under normal use.</p>

                    <h2>Warranty Claims:</h2>
                    <p>To make a warranty claim, please contact our customer support team at support@pivothawk.com with
                        proof of purchase and a description of the issue.</p>

                    <h2>Exclusions:</h2>
                    <p>The warranty does not cover damage caused by misuse, accidents, unauthorized alterations, or
                        normal
                        wear and tear.</p>

                    <h2>Contact Information</h2>
                    <p>For any questions or concerns regarding our policies and agreements, please contact us at:</p>

                    <h2>PivotHawk</h2>
<p>
                    163077B Township Rd 232, <br/>Newell County No. 4, AB T0J 1M0, <br/>Canada<br/>
                    Email: <a href="mailto:mg@pivothawk.com">mg@pivothawk.com</a><br/>
                    Phone: <a href="tel:14036331796">+14036331796</a>
</p>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default PrivacyPolicy;
