import {axiosReq} from "../utils/request";


export const signIn = async ({credentials}) => {
  const { email, password } = credentials;
  const basicAuth = 'Basic ' + btoa(email + ':' + password);
  const res = await axiosReq.get('/sign-in', {
      headers: {
        Authorization: basicAuth
      }
    })
  return res.data
}
export const changePasswordUser = async ({ formChangePass,customerId }) => {
  const formData = formChangePass?.values
  const res = await axiosReq.put(`/change-password/${customerId}`,{...formData})
  return res.data
}

export const getUsers = async () => {
  const res = await axiosReq.get('/users')
  return res.data
}

export const deleteUser = async (removeData) => {
  const res = await axiosReq.delete(`/user/${removeData.id}`,'')
  return res.data
}

export const editUsers = async ({editUser}) => {
  const res = await axiosReq.patch('/user', {...editUser})
  return res.data
}

export const addUsers = async ({newUser}) => {
  const res = await axiosReq.post('/user', {...newUser})
  return res.data
}
