import './_boxInfo.scss'

import arrow from '../../../../assets/images/arrow-right.svg'
import faq from '../../../../assets/images/faq.svg'
import dealer from '../../../../assets/images/dealer.svg'
import solution from '../../../../assets/images/solution.svg'
import {useNavigate} from "react-router-dom";



const BoxInfo = ({openPopup}) => {
    const navigate = useNavigate();

    return (             <div className='box-info'>
            <div className='container'>
                <div className='box-container'>
                    <div className='box'>
                        <div>
                            <div className='box-text'>
                                <h3>FAQ</h3>
                                <p>(frequently asked questions)</p>
                            </div>
                            <a href='/faq'><img src={arrow} alt="arrow"/></a>
                        </div>
                        <img src={faq}/>
                    </div>
                    <div className='box'>
                        <div>
                            <div className='box-text'>
                                <h3>Dealer Location</h3>
                            </div>
                            <a href="/location"><img src={arrow} alt="arrow"/></a>
                        </div>
                        <img src={dealer}/>
                    </div>
                    <div className='box'>
                        <div>
                            <div className='box-text'>
                                <h3>Product & Solution.</h3>
                            </div>
                            <a href='https://artaxe-studio.com/' target='_blank'><img src={arrow} alt="arrow"/></a>
                        </div>
                        <img src={solution}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxInfo
