import './_NoMatch.scss'

import {useEffect, useState} from "react";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";

const NoMatch = () => {

    return (<>
            <Header white={false} fixed={false}/>
            <div className='NoMatch-wrapper'>
                <h1>404</h1>
                <h3>Not Found</h3>
                <div className='btn-back'>
                    Return Home!
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default NoMatch
