import './_Header.scss'
import whiteLogo from '../../assets/images/white-logo.svg'
import blackLogo from '../../assets/images/black-logo.svg'
import whiteCart from '../../assets/images/cart-white.svg'
import blackCart from '../../assets/images/cart-black.svg'
import whiteUser from '../../assets/images/user-white.svg'
import blackUser from '../../assets/images/user-black.svg'
import {useNavigate} from "react-router-dom";
import { useLocation } from 'react-router-dom';


const Header = ({openPopup,white = false,fixed=false}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isDashboard = location.pathname === "/dashboard";
    const handleEmailClick = () => {
        window.location.href = 'mailto:mg@pivothawk.com?subject=PivotHawk%20customer%20support%20request&body=Your%20message';
    };
    const exit = () => {
        window.confirm('Do you really want to leave?');
        localStorage.clear();
        navigate('/login');
    }
    return (
        <header className={fixed ? 'fixed': 'relative' }>
            <div className='container'>
                <div className='box'>
                    <div className='logo'><a href="/"><img src={white ? whiteLogo : blackLogo} alt="logo"/></a></div>
                    <div className='btn'>
                        {isDashboard  && <button className={`${ white ?'text-white':'text-blue'} row-btn`} onClick={()=>handleEmailClick()}><img src="data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z' fill='%23010035'/%3E%3Cpath d='M7.88267 8.67922C7.87128 8.88355 8.03986 9.04907 8.2445 9.04907H9.48123C9.68834 9.04907 9.85302 8.88019 9.88084 8.67496C10.0143 7.69077 10.6898 6.97339 11.8938 6.97339C12.9221 6.97339 13.8648 7.48755 13.8648 8.72534C13.8648 9.67749 13.303 10.1155 12.4175 10.782C11.4082 11.5151 10.6084 12.3721 10.6656 13.7622L10.67 14.0874C10.6728 14.2925 10.8399 14.4573 11.045 14.4573H12.2615C12.4686 14.4573 12.6365 14.2894 12.6365 14.0823V13.9241C12.6365 12.8481 13.0459 12.5339 14.1504 11.696C15.0645 11.001 16.0166 10.2297 16.0166 8.61108C16.0166 6.34497 14.1028 5.25 12.0081 5.25C10.1075 5.25 8.02446 6.13592 7.88267 8.67922ZM10.218 17.3232C10.218 18.123 10.856 18.7134 11.732 18.7134C12.646 18.7134 13.2744 18.123 13.2744 17.3232C13.2744 16.4949 12.646 15.9141 11.732 15.9141C10.856 15.9141 10.218 16.4949 10.218 17.3232Z' fill='%23010035'/%3E%3C/svg%3E%0A" alt="cart"/><span>support</span></button>}

                        <button className={`${ white ?'text-white':'text-blue'} row-btn`}  onClick={() => navigate('/buy')}><img src={white ? whiteCart : blackCart} alt="cart"/><span>buy</span></button>

                        {!isDashboard ? <button className={`${white ? 'text-white' : 'text-blue'} row-btn`}
                                                onClick={() => navigate('/login')}>
                            <img src={white ? whiteUser : blackUser} alt="login"/><span>sign in</span>
                        </button> : <button className={`${white ? 'text-white' : 'text-blue'} row-btn`}>
                            <img src={white ? whiteUser : blackUser} alt="login"/><span onClick={()=>exit()}>Exit</span>
                        </button>}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
