import {BrowserRouter, Route, Routes} from 'react-router-dom';
import HomePage from "./components/page/Home";
import AboutCompany from "./components/page/AboutCompany/AboutCompany";
import LoginPage from "./components/page/LoginPage/LoginPage";
import {QueryClient, QueryClientProvider} from 'react-query'
import Dashboard from "./components/page/Dashboard/Dashboard";
import './scss/_global.scss'
import BuyPage from "./components/page/BuyPage/BuyPage";
import NoMatch from "./components/page/NoMatch/NoMatch";
import Faq from "./components/page/FAQ/Faq";
import PrivacyPolicy from "./components/page/PrivacyPolicy/PrivacyPolicy";
import Location from "./components/page/Location/Location";
const App = () => {
    const queryClient = new QueryClient()
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/about" element={<AboutCompany/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/buy" element={<BuyPage/>}/>
                    <Route path="/faq" element={<Faq/>}/>
                    <Route path="/privacy" element={<PrivacyPolicy/>}/>
                    <Route path="/location" element={<Location/>}/>
                    <Route path="*" element={<NoMatch/>}/>
                </Routes>
            </QueryClientProvider>
        </BrowserRouter>
    );
};


export default App;
