import axios from 'axios';

function getLocalRefreshToken() {
    const refreshToken = window.localStorage.getItem("refreshToken");
    return refreshToken ? JSON.parse(refreshToken) : null;
}

function getLocalAccessToken() {
    const accessToken = window.localStorage.getItem("accessToken");
    return accessToken ? JSON.parse(accessToken) : null;
}

export const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

export const request = () => {
    client.interceptors.request.use(
        (config) => {
            const token = getLocalAccessToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    client.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalConfig = error.config;
            if (error.response) {
                // Token expired
                if (error.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await refreshToken();
                       if(rs?.data?.message==='Invalid token'){
                           window.localStorage.clear();
                           return Promise.reject({message:'Invalid token'})
                       }
                        const newAccessToken = rs?.data?.accessToken;
                        const newRefreshToken = rs?.data?.refreshToken;

                        window.localStorage.setItem("accessToken", JSON.stringify(newAccessToken));
                        window.localStorage.setItem("refreshToken", JSON.stringify(newRefreshToken));

                        client.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
                        originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;

                        return client(originalConfig);
                    } catch (_error) {
                        if (_error.response && _error.response.data) {
                            return Promise.reject(_error.response.data);
                        }
                        return Promise.reject(_error);
                    }
                }

                if (error.response.status === 403 && error.response.data) {
                    return Promise.reject(error.response.data);
                }
            }
            return Promise.reject(error);
        }
    );

    return client;
};

function refreshToken() {
    return client.post("/refresh-token", {
        refreshToken: getLocalRefreshToken(),
    });
}

export const axiosReq = request();
