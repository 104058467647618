import SuccessSnackbarIcon from "../../assets/images/icons/SuccessSnackbarIcon.svg"
import ErrorSnackbarIcon from "../../assets/images/icons/ErrorSnackbarIcon.svg"
import {toast} from "react-toastify";
import "./Snackbar.css"

export const showSnackBar = (type, text = 'u forgot to write the text') => {

  let icon = null;

  switch (type) {
    case 'success':
      icon = <img src={SuccessSnackbarIcon} className="icon" alt="" />;
      toast.success(
        <div className="snackbarContent">
          {icon}
          <p className="textShackbar">{text}</p>
        </div>,
        {
          position: "top-right",
          className: "snackbarTab",
        }
      );
      break;
    case 'error':
      icon = <img src={ErrorSnackbarIcon} className="icon" alt="" />;
      toast.error(
        <div className="snackbarContent">
          {icon}
          <p className="textShackbar">{text}</p>
        </div>,
        {
          position: "top-right",
          className: "snackbarTab",
        }
      );
      break;
    default:
      break;
  }
};
